<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <validation-observer ref="notificationRef" #default="{ valid }">
          <b-form
            class="mt-1"
            @submit.prevent="handlePushNotificationToAllUsers"
          >
            <div class="d-flex my-2">
              <div class="d-flex">
                <feather-icon icon="BellIcon" size="19" />
                <h4 class="mb-0 ml-50">Hệ thống thông báo</h4>
              </div>
            </div>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Tiêu đề" label-for="title">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    vid="title"
                    rules="required"
                  >
                    <b-form-input name="title" v-model="title" />
                    <small v-if="errors.length > 0" class="text-danger"
                      >Vui lòng nhập tiêu đề!</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" class="order-lg-last">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="body"
                    vid="body"
                    rules="required"
                  >
                    <div class="d-flex">
                      <label for="bio-area">Nội dung thông báo</label>
                      <label class="ml-auto text-muted">
                        {{ (body && body.length) || 0 }} / 1000 Characters
                      </label>
                    </div>
                    <b-form-textarea
                      id="body"
                      v-model="body"
                      rows="4"
                      maxlength="1000"
                      placeholder="Nhập nội dung thông báo..."
                    />
                    <small v-if="errors.length > 0" class="text-danger"
                      >Vui lòng nhập nội dung thông báo!</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="!valid"
                class="mt-2"
              >
                <template v-if="loading">
                  <Spinner variant="light" />
                </template>
                <template v-else> Gửi </template>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import useToastHandler from "@/services/toastHandler";
import Spinner from "@core/components/Spinner";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BRow,
  VBModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BFormInput,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    Spinner,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      title: null,
      body: null,
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.apps.notification.notification.loading,
    }),
  },
  methods: {
    ...mapActions({
      pushNotificationToAllUsers:
        "notification/notification/pushNotificationToAllUsers",
    }),
    async handlePushNotificationToAllUsers() {
      const { title, body } = this;
      const res = await this.pushNotificationToAllUsers({ title, body });
      if (res) {
        this.showToastSuccess("Thành công", "Gửi thông báo thành công");
        this.resetForm();
      }
    },
    resetForm() {
      this.title = null;
      this.body = null;
      this.$refs.notificationRef.reset();
    },
  },
  setup() {
    const { showToastError, showToastSuccess } = useToastHandler();

    return {
      // Sidebar
      showToastError,
      showToastSuccess,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.text-wrap {
  line-height: 22px;
}
</style>
